<template>
  <div>
    <base-page
      :title="$t('registerManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
      @openDialog="openDialog"
    >
      <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.findRegisterUserList"
        :columns="columns"
        :defaultParams="defaultParams"
        v-model="tableRow"
      >
      <!-- 上传时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 发票信息 -->
      <template v-slot:option="{ row }">
        <!-- lang:发票信息 -->
        <el-button
          v-if="$page.showBtn('REGISTER_COMPANY_ALLOT_SP')"
          type="text"
          @click="openInvoice(row)"
        >
          {{ $t('functionEnum.COMPANY_INVOICE') }}
        </el-button>
        <!-- lang:跟踪备注 -->
        <el-button
          v-if="$page.showBtn('REGISTER_COMPANY_ALLOT_SP')"
          type="text"
          @click="openTrack(row)"
        >
          {{ $t('functionEnum.TRACKING_NOTES') }}
        </el-button>
        <!-- lang:继续注册 -->
<!--        <el-button-->
<!--          v-if="$page.showBtn('REGISTER_COMPANY_ALLOT_SP') && row.newRegister === 'YES'"-->
<!--          type="text"-->
<!--          @click="registerGoon(row)"-->
<!--        >-->
<!--          {{ $t('functionEnum.REGISTER_COMPANY_GOON') }}-->
<!--        </el-button>-->
        <!-- lang:关闭注册 -->
        <el-button
          v-if="$page.showBtn('REGISTER_COMPANY_ALLOT_SP')"
          type="text"
          @click="registerClose(row)"
        >
          {{ $t('functionEnum.REGISTER_COMPANY_CLOSE') }}
        </el-button>
      </template>
      </table-list>
    </base-page>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <allot v-if="showAllot" :tableRow="tableRow" @closePage="closePage" />
    <invoice v-if="showInvoice" :tableRow="tableRow" @closePage="closePage" />
    <notes v-if="showNotes" :tableRow="tableRow" @closePage="closePage" />
    <proceed v-if="showProceed" :tableRow="tableRow" @closePage="closePage" />
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="dialogTableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
import Allot from './allot.vue'
import Detail from './detail'
import Invoice from './invoice'
import Notes from './notes'
import Proceed from './proceed'

export default {
  components: {
    Detail,
    Allot,
    Invoice,
    Notes,
    Proceed
  },
  data() {
    const nowTime = new Date().getTime()
    const prevTime = nowTime - 365 * 24 * 60 * 60 * 1000
    const startCreateTime = this.$util.format(prevTime, 'yyyy-MM-dd')
    const endCreateTime = this.$util.format(nowTime, 'yyyy-MM-dd')
    return {
      defaultParams: {
        // startCreateTime: new Date(startCreateTime + ' 00:00:00').getTime(),
        // endCreateTime: new Date(endCreateTime + ' 23:59:59').getTime(),
        needFullAddress: 'YES',
        needFullIndustry: 'YES',
      },
      // 搜索
      formData: {
        // createTime: [startCreateTime, endCreateTime]
        serviceProviderId:''
      },
      config: [
        // lang:所属行业
        {
          tag: 'el-cascader',
          label: this.$t('registerManage.industry'),
          prop: 'industryId',
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },

         // lang:联系地址
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.address'),
          prop: 'addressId',
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:开始时间和结束时间
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
        // lang:服务商
        {
          tag: 'dialog-define',
          label: this.$t('registerManage.serverName'),
          prop: 'serviceProviderId',
          hidden: false,
          span: 8,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
      ],
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
      // 表格页
      tableRow: null,
      contextFromObj: this.$util.listToObj(this.$dict.contextFrom()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      columns: [
        // lang:注册时间
        {
          label: this.$t('registerManage.registerTime'),
          prop: 'createTime',
        },
        // lang:企业名称
        {
          label: this.$t('registerManage.companyName'),
          prop: 'name',
        },
        // lang:所属行业
        {
          label: this.$t('registerManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
         // lang:联系地址
        {
          label: this.$t('registerManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('registerManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('registerManage.information'),
          prop: 'phone',
        },
        // lang:联系邮箱
        {
          label: this.$t('registerManage.email'),
          prop: 'email',
        },
        // lang:所属服务商
        {
          label: this.$t('registerManage.serviceprovider'),
          prop: 'serviceProvider',
          callback(row) {
            return row && row.serviceProvider && row.serviceProvider.name
          },
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '300px',
          align: 'center',
        },
      ],
      // 表单页
      showDetail: false,
      showAllot: false,
      showInvoice: false,
      showNotes: false,
      showProceed: false,
    }
  },
  mounted() {
    const language = this.$util.getLocalStorage('lang')
    this.dictFindTree('COMPANY_INDUSTRY', language)
    this.dictFindTree('ADDRESS', language)
    this.dictFindTree()
  },
  methods: {
     // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        if (dictType === 'COMPANY_INDUSTRY') {
          this.config[0].tagProps.options = data || []
        } else {
          this.config[1].tagProps.options = data || []
        }
      })
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('assignDistribute.targetCompany')
      this.dialogUrl = this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      this.showAllot = false
      this.showInvoice = false
      this.showNotes = false
      this.showProceed = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      const { serviceProviderId } = formData
      formData.industryId = formData.industryId.pop()
      formData.addressId = formData.addressId.pop()
      if(serviceProviderId) formData.serviceProviderId = serviceProviderId.id
      this.defaultParams = {}
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (!this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'view':
          this.registerGoon(this.tableRow)
          // this.showDetail=true
          break
        case 'add':
          this.showAllot = true
          break
        default: // do something
      }
    },
    // 打开发票弹框
    openInvoice(row) {
      this.$store.commit('updateCmd', 'invoice')
      this.$store.commit('updateDialogTitle', this.$t('companyManage.invoice'))
      this.tableRow = row
      this.showInvoice = true
    },
    //跟踪备注
    openTrack(row) {
      this.$store.commit('updateCmd', 'notes')
      this.$store.commit('updateDialogTitle', this.$t('registerManage.remarks'))
      this.tableRow = row
      this.showNotes = true
    },
    // 继续注册
    registerGoon(row){
      this.$store.commit('updateCmd', 'proceed')
      this.$store.commit('updateDialogTitle', this.$t('registerManage.proceed'))
      this.tableRow = row
      this.showProceed = true
    },
    // 关闭注册
    registerClose(row){
      this.$element
        .showConfirm({
          content: this.$t('base.closeRegister'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.registercloseRegisterCompany,
            data: {
              id: row.id
            },
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.closeRegisterSuccess'), 'success')
            this.search()
          })
        })
    }

  },
}
</script>
