<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
    width="650px"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {
        registerId:''
      },
      config: [
        // lang:备注
        {
          label: this.$t('registerManage.remarks'),
          prop: 'notes',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 3,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.formData.registerId = this.tableRow.id
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.updateNotes,
        data: formData,
      }).then(() => {
        // lang:跟踪备注添加成功
        this.$element.showMsg(
          this.$t('registerManage.setRemarksSuccess'),'success' )
        this.$emit('closePage', 'notes')
      })
    },
  },
}
</script>